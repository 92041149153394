<template>
    <div>
      <h1 class="text-5xl text-white title"> Papo de Boleiro </h1>
      <img src="@/assets/images/kingball.png" class="md:h-80 m-auto"/>
        <button @click="auth" class="py-2 px-4 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-700 focus:outline-none">
            <i class="fab fa-google mr-2"></i> Login with Google
        </button>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LoginView',
  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
    })
  },
  mounted() {
    this.logout();
  },
  methods: {
    async auth() {
      await this.login();
      return this.$router.push('/');
    },
    ...mapActions('user', {
      login: 'login',
      logout: 'logout'
    })
  }
}
</script>