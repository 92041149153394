
const abbreviations = {
    118: "BAH",
    119: "INT",
    120: "BOT",
    121: "PAL",
    123: "SPT",
    124: "FLU",
    125: "AME",
    126: "SAO",
    127: "FLA",
    128: "SAN",
    129: "CEA",
    130: "GRE",
    131: "COR",
    132: "CHA",
    133: "VAS",
    134: "CAP",
    135: "CRU",
    136: "VIT",
    140: "CRI",
    144: "ACG",
    145: "AVA",
    147: "CFC",
    151: "GOI",
    152: "JUV",
    154: "FOR",
    794: "BGT",
    1062: "CAM",
    1193: "CUI"
}

export default abbreviations;