// import firebase from '@/utils/firebase';
import jwt_decode from 'jwt-decode';
// import LogRocket from 'logrocket';

import UserService from '@/services/userService';
import {  getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";

const state = {
    userProfile: {},
    loggedIn: false,
};

const getters = {
    userProfile: ({ userProfile}) => userProfile,
    loggedIn: ({ loggedIn }) => loggedIn,
};

const mutations = {
    USER_DETAILS(state, userProfile) {
  
        if (state.userProfile.id !== undefined) return;

        state.loggedIn = true;
        state.userProfile = {
          name: userProfile.name,
          email: userProfile.email,
          picture: userProfile.photoURL,
          id: userProfile.id,
          rules: userProfile.rules,
        };
    },
    LOGOUT(state) {
        state.loggedIn = false;
        state.userProfile = {};
    }
};

const actions = {
    async login(store) {
        // if (store.state.loggedIn) return; //talvez trocar por verificação de jwt
        const provider = new GoogleAuthProvider();
        const auth = getAuth();

        try {
            const result = await signInWithPopup(auth, provider)
            // This gives you a Google Access Token. You can use it to access the Google API.
            GoogleAuthProvider.credentialFromResult(result);
            // const token = credential.accessToken;
            // The signed-in user info.
            const userInfo = result.user;
            let user = {
                name: userInfo.displayName,
                email: userInfo.email,
                photoURL: userInfo.photoURL
            }
            let responseLogin = await UserService.login(user);
            store.commit(`USER_DETAILS`, user);

            if (responseLogin.message !== undefined) {
                return;
            }
            let payload = jwt_decode(responseLogin.token);

            user.id = payload._id;
            user.name = payload.name;
            user.rules = payload.rules;
            store.commit(`USER_DETAILS`, user);

                // LogRocket.identify(user.id, {
            //         name: user.name,
            //         email: user.email,
            //       });
        } catch (error) {
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.log(error, credential);
        }
    },

    async logout(store){
        try {
            localStorage.removeItem('token');
            const auth = getAuth();
            await signOut(auth);
            store.commit(`LOGOUT`);
        } catch(error) {
            console.log(error);
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}