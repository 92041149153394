<style src="@vueform/multiselect/themes/default.css"></style>
<template>
  <div>
    <button class="w-full px-2 py-1 rounded outline-none focus:outline-none mr-1 mb-1 sm:text-green-600 text-white sm-hover:text-green-900 sm:bg-transparent bg-blue-500 font-bold sm:font-normal border-0 sm:border-solid sm:border-blue-500 sm-hover:bg-blue-800 sm-hover:text-white hover:text-green-800 sm:normal-case uppercase text-sm" type="button"  v-on:click="toggleModal()">
      Associar
    </button>
    <div v-if="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-sm">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
            <h3 class="text-3xl font-semibold">
              Associe um usuario
            </h3>
            <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" v-on:click="toggleModal()">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <p class="my-4 text-gray-600 text-lg leading-relaxed">

              <Multiselect
                v-model="value"
                :options="options"
                />
            </p>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
            <button class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="toggleModal()">
              Close
            </button>
            <button class="text-green-500 bg-transparent border border-solid border-green-500 hover:bg-green-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="assignUser()">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>

import Multiselect from '@vueform/multiselect'
import UserService from '@/services/userService';

export default {
  name: "ModalUserAssign",
  components: {
      Multiselect,
  },
  props: [
    'options',
    'userApproval',
    'rowId'
  ],
  mounted() {
    // UserService.getUsers().then(users => {
    //     users;
    // });
  },
  methods: {
    toggleModal: function(){
      this.showModal = !this.showModal;
    },
    assignUser: function() {
        console.log(this.userApproval.name,this.userApproval.email, this.value);
        let newUser = {
          _id: this.value,
          name: this.userApproval.name,
          email: this.userApproval.email,
          approvalId: this.userApproval._id
        }

        UserService.assignUser(newUser).then(data => {
          this.$emit('update', this.rowId, data.message);
        }).catch(err => {
          this.$emit('error', err.message)
        });

        this.showModal = !this.showModal;
    }
  },
  data() {
      return {
        showModal: false,
        value: null,
      }
    }
}
</script>

<style>
@import "@vueform/multiselect/themes/default.css";
</style>
