<template>
    <div class="block sm:hidden w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50">
        <span class="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style="top: 30%; left: -10%;">
            <i class="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
    </div>
</template>

<script>
export default {
  name: 'LoadingComponent'
}
</script>