import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios';
import HomeView from '@/views/HomeView'
import LoginView from "@/views/LoginView"
import UsersView from '@/views/UsersView'
import UserForm from '@/views/UserForm'
import ApprovalView from '@/views/ApprovalView'
import RankingView from '@/views/RankingView'
import CurrentRound from '@/views/CurrentRound'
import SaveBetting from '@/views/SaveBetting'
import BetRules from '@/views/BetRules'
import BettingSaved from '@/views/BettingSaved'
import ReportsBetting from '@/views/ReportsBetting'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: LoginView
  },
  {
    path: "/users/:userId?",
    name: "Users",
    component: UsersView,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/user-form/:userId?",
    name: "UserForm",
    component: UserForm,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/approval",
    name: "Approval",
    component: ApprovalView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/ranking",
    name: "ranking",
    component: RankingView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/current-round/:userId?/:roundId?",
    name: "Current Round",
    component: CurrentRound,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-betting/:userId/:roundId?',
    name: "NewBetting",
    component: SaveBetting,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/bet-rules",
    name: "Bet Rules",
    component: BetRules,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/betting-saved/:roundId?",
    name: "Betting Saved",
    component: BettingSaved,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/reports-betting/:roundId?",
    name: "Reports Betting",
    component: ReportsBetting,
    meta: {
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  const token = localStorage.getItem('token')
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {

    let jwt = localStorage.getItem('token');
    if (jwt === undefined || jwt === null) {
      next({
        path: '/login'
      })
    } else {
      //verify if is on login page, if yes, redirect to home page
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
