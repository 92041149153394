import axios from 'axios';
import jwt_decode from 'jwt-decode';

const url = process.env.VUE_APP_API_URL;

class UserService {

    static getUserData() {
        let token = localStorage.getItem('token');
        if (token !== null) {
            try {
                return jwt_decode(token);
            } catch (err) {
              console.log(err);
              return null;
            }
        }
    }

    static async login (user) {
        return new Promise((resolve, reject) => {
            axios.post(`${url}/auth/login`, user)
            .then(response => {
                if (response.data.token !== undefined) {
                    localStorage.setItem('token', response.data.token);
                }
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async getUsers() {
        return new Promise((resolve, reject) => {
            axios.get(`${url}/users?betting=0`, {
                headers: {
                    "permission": ['super-admin']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async getUser(userId) {
        return new Promise((resolve, reject) => {
            axios.get(`${url}/users/${userId}`, {
                headers: {
                    "permission": ['super-admin']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async saveUser(user) {
        if (!user._id) {
            return this.createUser(user);
        }
        return this.updateUser(user);
    }

    static async createUser(user) {
        return new Promise((resolve, reject) => {
            axios.post(`${url}/users`, user, {
                headers: {
                    "permission": ['super-admin']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async updateUser(user) {
        delete user.betting;
        return new Promise((resolve, reject) => {
            axios.put(`${url}/users/${user._id}`, user, {
                headers: {
                    "permission": ['super-admin']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async deleteUser(userId) {
        return new Promise((resolve, reject) => {
            axios.delete(`${url}/users/${userId}`, {
                headers: {
                    "permission": ['super-admin']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async assignUser(user) {
        return new Promise((resolve, reject) => {
            axios.post(`${url}/approval`, user, {
                headers: {
                    "permission": ['super-admin']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async getApprovalUsers() {
        return new Promise((resolve, reject) => {
            axios.get(`${url}/approval`, {
                headers : {
                    "permission": ['super-admin']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async deleteApprovalUser(userId) {
        console.log(userId);
        return new Promise((resolve, reject) => {
            axios.delete(`${url}/approval/${userId}`, {
                headers : {
                    "permission": ['super-admin']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }
}

export default UserService;