import { createStore } from 'vuex'
import user from '@/store/user'

// const userState = createPersistedState({
//   paths: ['user']
// })

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
  }
})
