<template>
  <div class="bg-white absolute hidden rounded-md shadow-lg ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 sm:block w-2/12">
    <div class="py-1">
        <router-link to="/ranking" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Ranking</router-link>
        <router-link to="/current-round"  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Rodada Atual</router-link>
        <router-link :to="{ name: 'NewBetting', params: {userId: this.getUserId()}}" v-bind:class="{'hidden': !hasPermission('UserBolao')}" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Criar Palpite</router-link>
        <router-link to="/bet-rules" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Regras</router-link>
        <router-link to="/betting-saved" v-bind:class="{'hidden': !hasPermission('SuperAdmin')}" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Palpites Cadastrados</router-link>
        <router-link to="/reports-betting" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Ranking Report</router-link>
        <a href="#" @click="updateRanking()" v-bind:class="{'hidden': !hasPermission('SuperAdmin')}" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Atualizar Ranking</a>
      </div>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from "vue-toastification";
import RankingService from '@/services/rankingService';

export default {
  name: 'NavigationBetting',
  setup() {
      const toast = useToast();
      return { toast }
  },
  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
    })
  },
  created() {
    let token = localStorage.getItem('token');
    if (token !== null) {
     try {
        let payload = jwt_decode(token);
        this.userData = payload;
      } catch (err) { console.log(err)}
    }
  },
  methods: {
    getUserId() {
      if (this.userData.length == 0) {
        return false;
      }
      return this.userData._id;
    },
    async auth() {
      await this.login();
      this.$router.push('/');
    },
    async logoff() {
      await this.logout();
      this.$router.push('/login');
    },
    toggleMenu(menu) {
        switch (menu) {
          case 'betting':
            this.showBetting = !this.showBetting
            return;
          case 'betting-mobile':
            this.showBettingMobile = !this.showBettingMobile
            return;
          case 'profile':
            this.showProfile = !this.showProfile;
            return;
          case 'navbar':
            this.showMenu = !this.showMenu;
            return;
          default:
            return;
        }
    },
    hasPermission(permission) {
      if (this.userData.length == 0) {
        return false;
      }
      let { rules } = this.userData;
      return rules.filter(data => {
        return data === permission || data === "SuperAdmin"
      }).length > 0;
    },
    updateRanking() {
      RankingService.updateRanking().then(data => {
        console.log(data.status);
        this.toast.success("Atualizando o ranking.", {
          onClose: () => {
            location.reload()
          }
        });
      }).catch(error => {
        this.toast.error(`Erro ao atualizar: ${error.getMessage()}`);
      })
    },
    ...mapActions('user', {
      login: 'login',
      logout: 'logout'
    })
  },
  data() {
    return {
      showMenu: false,
      showProfile: false,
      showBetting: false,
      showBettingMobile: false,
      userData: ''
    }
  },
}
</script>