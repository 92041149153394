<template>
<div>
    <NavigationMenu />

    <header class="bg-green-100 shadow-md">
      <div class="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold leading-tight text-gray-900">
          Bolão do Brasileiro
        </h1>
      </div>
    </header>

    <div class="container mx-auto lg:flex mt-4 justify-between px-2 sm:p-0 sm:mt-4">
        <NavigationBetting />
        
        <div class="w-full sm:w-9/12 mx-auto mr-0">
          <RankingReportTable :userData=userData />
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import NavigationMenu from '@/components/NavigationMenu';
import NavigationBetting from '@/components/NavigationBetting';
import RankingReportTable from '@/components/RankingReportTable';
import UserService from '@/services/userService';

export default {
  name: 'ReportsBetting',
  components: {
    NavigationMenu,
    RankingReportTable,
    NavigationBetting
  },
  created() {
    this.userData = UserService.getUserData();
  },
  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
    })
  },
  data(){
    return {
      userData: null,
    }
  },
}
</script>