import axios from 'axios';
import RoundHelper from '../utils/roundHelper';

const url = process.env.VUE_APP_API_URL;

class ApiService {

    static async login (user) {
        return new Promise((resolve, reject) => {
            axios.post(`${url}/auth/login`, user)
            .then(response => {
                if (response.data.token !== undefined) {
                    localStorage.setItem('token', response.data.token);
                }
                resolve(response.data);
            }).catch(err => {
                localStorage.removeItem('token');
                console.log(err);
                reject(err);
            });
        })
    }
    static async getCurrentRound() {
        return new Promise((resolve, reject) => {
            axios.get(`${url}/round/current`, {
                headers: {
                    "permission": ['betting']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async createBetting(userId, bet) {
        return new Promise((resolve, reject) => {
            axios.post(`${url}/users/${userId}/bets`, bet,  {
                headers: {
                    "permission": ['betting', 'super-admin']
                }
            })
            .then(response => {
                console.log(response);
                if (response.status != 200 && response.status != 201) {
                    return reject(response.data);
                }
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async updateBetting(userId, bet) {
        return new Promise((resolve, reject) => {
            axios.put(`${url}/users/${userId}/bets/${bet._id}`, bet,  {
                headers: {
                    "permission": ['betting-update']
                }
            })
            .then(response => {
                console.log(response);
                if (response.status != 200 && response.status != 201) {
                    return reject(response.data);
                }
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async getUserBet(userId, roundId) {
        return new Promise((resolve, reject) => {
            axios.get(`${url}/users/${userId}/bets/${roundId}`, {
                headers: {
                    "permission": ['betting']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static roundInitiaded(fixtures) {

        let firstMatch = RoundHelper.getFirstMatch(fixtures);

        let eventTimeStamp = this.convertTimestampToGmtBr(
            (firstMatch.eventTimestamp * 1000)
        );

        let currentTimestamp = this.convertTimestampToGmtBr(
            (new Date).getTime()
        );

        if (eventTimeStamp.getTime() <= currentTimestamp.getTime()) {
            return true;
        }

        return false;
    }

    static convertTimestampToGmtBr(timestamp) {
        var date = new Date(timestamp);
        var dateString = date.toLocaleString("en-US", {timeZone: "America/Sao_Paulo"});
        return new Date(dateString);
    }

    static async getCurrentRoundWithBets(userId, roundId) {
        return new Promise((resolve, reject) => {
            axios.get(`${url}/users/${userId}/bets/${roundId}/consolidate`, {
                headers: {
                    "permission": ['betting']
                }
            })
            .then(response => {
                if (response.status != 200 && response.status != 201) {
                    reject(response.data);
                }
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async getRoundIds() {
        return new Promise((resolve, reject) => {
            axios.get(`${url}/round_ids`, {
                headers: {
                    "permission": ['betting']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }
}

export default ApiService;