<template>
    <div>
      <div v-if="isRoundAlreadyStarted && !hasFixturePostponed" class="text-white sm:px-6 py-2 border-0 rounded relative mb-4 bg-indigo-500">
        <span class="inline-block align-middle mr-8">
          <b class="capitalize">Alert!</b> Rodada em andamento.
        </span>
      </div>
      <div class="w-10/2">
        <div class="px-2 py-2 bg-white font-bold rounded-md mb-4">
          {{ this.formatTitle(currentRound._id)}}
        </div>
        <form id="app-form" @submit="submitForm" >
          <div class="flex justify-between sm:justify-end">
            <div class="w-full sm:w-4/12 mr-2" v-show="hasPermission('SuperAdmin')">
              <select class="multiselect-wrapper bg-white" v-model="currentRound._id" @change="onChangeSelect">
                <option class="multiselect-option text-center" v-for="round in roundIds" :key="round.value" v-bind:value="round.value">{{ round.label }}</option>
              </select>
            </div>
            <div v-show="hasPermission('UserBolao')" class="w-full sm:w-4/12 mr-2">
              <button @click="shareBet" :disabled="disableSubmit" class="text-green-700 bg-white w-full hover:bg-green-800 hover:text-white font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" >
                <i class="fab fa-whatsapp"></i> Compartilhar
              </button>
            </div>
            <div v-show="!isBetDisabled() || hasFixturePostponed" class="w-full sm:w-4/12 ">
              <button :disabled="disableSubmit" class="text-green-700 bg-white w-full hover:bg-green-800 hover:text-white font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="submit" style="transition: all .15s ease" >
                Salvar Palpite
              </button>
            </div>
          </div>
          
          <ol class="shadow-lg">
            <li v-for="(fixture, id) in currentRound.fixtures" :key="id" class="bg-white px-2 py-2 mb-1 h-26 rounded-sm shadow-md flex justify-around">

              <div class="w-full">
                <div v-bind:class="{'line-through': fixturePostponed(fixture.status)}" class="mb-2 font-medium text-gray-600">
                  {{ this.formatDate(fixture.eventDate)}} 
                </div>
                <div class="flex justify-between mb-1">
                    <div class="w-5/12 font-bold flex justify-end mr-4">
                      <div class="pt-1">{{ this.getAbbr(fixture.homeTeam.teamId) }}</div>
                      <div class="h-8 w-8 ml-2">
                        <img :src=fixture.homeTeam.teamLogo />
                      </div>
                    </div>
                    <div class="w-5/12 sm:w-2/12 flex justify-center">
                      <div class="mr-2 text-2xl font-bold">
                        <input v-model.number="fixture.goalsHomeTeam" min="0" :disabled="isFixtureBetDisabled(fixture)" type="number" class="block text-center appearance-none placeholder-gray-500 placeholder-opacity-100 border border-fuchsia-300 rounded-md w-full py-0 sm:pl-2 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:border-fuchsia-400 focus:ring-fuchsia-200" />
                      </div>
                      <div class="mt-1 mr-2">x</div>
                      <div class="text-2xl font-bold">
                        <input v-model.number="fixture.goalsAwayTeam" min="0" :disabled="isFixtureBetDisabled(fixture)" type="number" class="block text-center appearance-none placeholder-gray-500 placeholder-opacity-100 border border-fuchsia-300 rounded-md w-full py-0 sm:pl-2 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:border-fuchsia-400 focus:ring-fuchsia-200" />
                      </div>
                    </div>
                    <div class="w-5/12 font-bold flex justify-start ml-4">
                      <div class="h-8 w-8 mr-2">
                        <img :src=fixture.awayTeam.teamLogo />
                      </div>
                      <div class="pt-1">{{ this.getAbbr(fixture.awayTeam.teamId) }}</div>
                    </div>
                </div>
                <div v-if="fixturePostponed(fixture.status)" class="flex justify-center mb-1 text-red-400">
                  <span class="font-thin text-xs">Partida adiada</span>
                </div>
              </div>
            </li>
          </ol>
        </form>
      </div>
    </div>
</template>

<script>
import ApiService from '@/services/apiService';
import WhatsAppService from '@/services/whatsappService';
import TeamInfoMap from '@/utils/teamInfoMap';
import { mapGetters } from 'vuex';
import { useToast } from "vue-toastification";
import { useRoute } from 'vue-router'

export default {
  name: 'SaveBettingForm',
  setup() {
      const toast = useToast();
      return { toast }
  },
  props: [
    'userData',
    'roundId'
  ],
  created() {
    this.$watch(
      () => this.$route.params,
      async (toParams, previousParams) => {
        console.log(toParams, previousParams);
        this.getData(toParams.userId, toParams.roundId);
      }
    )
  },
  async mounted() {
    ApiService.getRoundIds().then(rounds => {
      let options = rounds.map(data => {
        return {
          value: data._id,
          label: this.formatTitle(data._id)
        }
      });
      this.roundIds = options;
    });

    const route = useRoute();
    this.userId = route.params.userId;
    if (!this.userId) {
      this.userId = this.userData._id
    }

    await this.getData(this.userId, this.roundId);
    this.hasFixturePostponed = this.currentRound.hasFixturePostponed;
    this.isRoundAlreadyStarted = ApiService.roundInitiaded(this.currentRound.fixtures);
  },
  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
    })
  },
  methods: {
    async getData(userId, roundId) {
      var currentRound = await ApiService.getCurrentRound();
      this.currentRound = currentRound[0];
      if (!roundId) {
        roundId = this.currentRound._id;
      }

      let userBet = await ApiService.getUserBet(userId, roundId);
      if (userBet.length > 0) {
        this.isNewBet = false;
        this.currentRound = userBet[0].betting.rounds[0];
        this.userBetName = userBet[0].name;
      }
    },

    onChangeSelect(event) {
      let roundId = event.target.value;
      return this.$router.push({
          name: "NewBetting",
          params: { userId: this.userId, roundId: roundId }
        })
    },

    async submitForm(e) {
      this.disableSubmit = true;
      e.preventDefault();

      if (this.isBetDisabled() && !this.hasFixturePostponed) {
        return;
      }

      console.log(this.currentRound);
      if (!confirm("Após salvar não será possivel editar os palpites, deseja continuar?")) {
        this.disableSubmit = false;
        return;
      }

      if (this.isNewBet) {
        this.saveBet();
        return;
      }

      if (this.hasPermission("SuperAdmin") || this.hasFixturePostponed) {
        this.updateBet();
        return;
      }

      alert("Você não tem permissão para atualizar o palpite, caso tenha algum erro entre em contato com os administradores");
    },

    saveBet() {
      ApiService.createBetting(this.userId, this.currentRound).then((data) => {
        console.log(data.status);
        this.isNewBet = false;
        this.disableSubmit = false;
        this.toast.success("Palpite salvo com sucesso!");
      });
    },

    updateBet() {
      ApiService.updateBetting(this.userId, this.currentRound).then((data) => {
        console.log(data.status);
        this.isNewBet = false;
        this.disableSubmit = false;
        this.toast.success("Palpite atualizado com sucesso!");
      });
    },

    shareBet() {
      if (this.isNewBet) {
        this.saveBet();
      }
      WhatsAppService.shareBet(this.currentRound, this.userData, this.userBetName);
    },

    getAbbr(teamId) {
      return TeamInfoMap.mapNameToAbbreviation(teamId);
    },

    formatDate(dateString)
    {
      return new Date(dateString).toLocaleString();
    },

    toggleBet() {
      this.showBet = !this.showBet;
    },

    formatTitle(title) {
      if (title !== undefined) {
        return title.replaceAll("_", " ");
      }
      return title;
    },

    isBetDisabled() {
      if (this.hasPermission("SuperAdmin")) {
        return false;
      }

      return this.isRoundAlreadyStarted || !this.isNewBet;
    },

    isFixtureBetDisabled(fixture) {
      if (this.hasPermission("SuperAdmin")) {
        return false;
      }

      if (this.IsInvalidStatus(fixture.status)) {
        return true;
      }

      return this.isBetDisabled() && !this.hasFixturePostponed;
    },

    IsInvalidStatus(status) {
      return status == "Match Finished" || status == "Match Postponed";
    },

    hasPermission(permission) {
      if (this.userData.length == 0) {
        return false;
      }
      let { rules } = this.userData;
      return rules.filter(data => {
        return data === permission || data === "SuperAdmin"
      }).length > 0;
    },

    fixturePostponed(status) {
      return status == "Match Postponed";
    }
  },
  data(){
    return {
      currentRound: {},
      userBet: {},
      isNewBet: true,
      isRoundAlreadyStarted: false,
      userId: null,
      disableSubmit: false,
      hasFixturePostponed: false,
      roundIds : [],
      userBetName: null,
    }
  },
}
</script>