<template>
    <div>
      <div class="px-2 py-2 bg-white flex justify-between font-bold rounded-md mb-4">
        <div v-if="this.currentRoundId != 'Regular_Season_-_1'"  class="w-10">
          <button v-on:click="getRoundId(this.currentRoundId, 'prev')" >
            <i class="fas fa-angle-double-left"></i>
          </button>
        </div>
        <div class="w-full">
          {{ this.formatTitle(this.currentRoundId)}}
        </div>
        <div class="w-10">
          <button v-on:click="getRoundId(this.currentRoundId, 'next')" >
            <i class="fas fa-angle-double-right"></i>
          </button>
        </div>
      </div>
      <LoadingComponent v-if="this.loading" />
      <div class="flex justify-between sm:justify-end">
      </div>
      <div class="hidden sm:block">
        <table class="table-auto font-bold min-w-full divide-y divide-gray-200 shadow-lg rounded-lg">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(user) in ranking" :key="user._id" :class="getBgRows(user)">
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                  <router-link :to="{ name: 'NewBetting', params: {userId: user._id}}" 
                  class="text-green-600 hover:text-green-900">
                  {{ user.name }}
                </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="block sm:hidden">
        <ol class="shadow-lg">
          <li v-for="(user) in ranking" :key="user._id" :class="getBgRows(user)" class="flex justify-between px-2 py-2 mb-1 rounded-md">
            <div clas="flex-col w-full align-middle">
              <div class="mt-1">
                <router-link :to="{ name: 'NewBetting', params: {userId: user._id}}"
                  class="font-bold pt-2">
                  {{ user.name }}
                </router-link>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>
</template>

<script>
import BetService from '@/services/betService';
import ApiService from '@/services/apiService';
import LoadingComponent from '@/components/LoadingComponent.vue';

export default {
  name: 'BettingSavedTable',
  components: {
    LoadingComponent,
  },
  props: [
    'userData',
    'roundId'
  ],
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        console.log(toParams, previousParams);
        this.loading = true;
        this.getData(toParams.roundId);
      }
    )
  },
  mounted() {
    this.getData(this.roundId);
  },
  methods: {
    async getData(roundId) {
      if (!roundId) {
        var currentRound = await ApiService.getCurrentRound();
        roundId = currentRound[0]._id;
      }

      BetService.getBettingSaved(roundId).then(data => {
        this.currentRoundId = roundId
        this.ranking = data;
        this.loading = false;
      });
    },
    getRoundId(roundId, type) {
      let round = roundId.split("_");
      let roundNumber = round.pop();
      roundNumber = parseInt(roundNumber);

      if (type === "prev") {
        let prevNumber = roundNumber - 1;
        round.push(prevNumber + '');
        roundId = round.join("_");
        return this.$router.push({
          name: "Betting Saved",
          params: { roundId: roundId }
        })
      }

      if (type === "next") {
        let prevNumber = roundNumber + 1;
        round.push(prevNumber + '');
        roundId = round.join("_");
        return this.$router.push({
          name: "Betting Saved",
          params: { roundId: roundId }
        })
      }
    },
    formatTitle(title) {
      if (title !== undefined) {
        return title.replaceAll("_", " ");
      }
      return title;
    },
    hasPermission(permission) {
      if (this.userData.length == 0) {
        return false;
      }
      let { rules } = this.userData;
      return rules.filter(data => {
        return data === permission || data === "SuperAdmin"
      }).length > 0;
    },
    getBgRows(user) {
      if (user.bet) {
        return "bg-green-100"
      }
      return "bg-red-100"
    },
  },
  data(){
    return {
      ranking: [],
      loading: true,
      currentRoundId: undefined,
    }
  },
}
</script>