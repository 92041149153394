<template>
<NavigationMenu />
  <div class="container mx-auto lg:flex justify-between -mt-4 px-2 sm:p-0 sm:mt-4">

      <div class="w-full cursor-pointer rounded-lg shadow-xl overflow-hidden relative mt-8 px-8 pt-16 pb-32 text-white bg-white" style="max-width:500px;">
          <h3 class="text-3xl font-semibold text-green-900 leading-tight mb-20 relative z-10">Bolão do Brasileirao</h3>
          <div class="absolute bottom-0 -mb-18 left-0 ml-10 w-full z-0">
              <img src="@/assets/images/bolao.svg" class="opacity-50 hover:opacity-100" />
          </div>
          <p class="relative z-10">
              <router-link to="/ranking" class="py-4 px-8 rounded shadow hover:shadow-lg text-white text-lg bg-gray-900 hover:bg-black focus:outline-none transition-all duration-300 ease-in-out">Acessar</router-link>
          </p>
      </div>

      <div class="w-full rounded-lg shadow-xl overflow-hidden relative mt-8 px-8 pt-16 pb-32 text-white bg-white" style="max-width:500px;">
         <div class="text-black font-bold bg-red-500 relative z-10 transform -rotate-45 -translate-x-36 -translate-y-2">
          Construction...
        </div>
          <h3 class="text-3xl font-semibold text-green-900 leading-tight mb-12 relative z-10">Papo de Boleiro</h3>
          <div class="absolute bottom-0 -mb-18 left-0 ml-10 w-full z-0">
              <img src="@/assets/images/racha.svg" class="opacity-50" />
          </div>
      </div>

      <div class="w-full rounded-lg shadow-xl overflow-hidden relative mt-8 px-8 pt-16 pb-32 text-white bg-white" style="max-width:500px;">
        <div class="text-black font-bold bg-red-500 relative z-11 transform -rotate-45 -translate-x-36 translate-y-2">
          Construction...
        </div>
          <h3 class="text-3xl font-semibold text-green-900 leading-tight mb-12 relative z-10">Cartola</h3>
          <p class="relative z-10">
              <a href="#" class="py-4 px-8 rounded shadow hover:shadow-lg text-white text-lg bg-gray-900 hover:bg-black focus:outline-none transition-all duration-300 ease-in-out">Solicitar Acesso</a>
          </p>
          <div class="absolute bottom-0 -mb-18 left-0 ml-10 w-full z-0">
              <img src="@/assets/images/cartola.svg" class="opacity-50" />
          </div>
      </div>

    </div>
</template>

<script>
// @ is an alias to /src
import NavigationMenu from '@/components/NavigationMenu.vue'

export default {
  name: 'HomeView',
  components: {
    NavigationMenu
  }
}
</script>
