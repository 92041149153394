<template>
<div>
<nav class="bg-green-900  -mt-16">
  <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
    <div class="relative flex items-center justify-between h-16">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button-->
        <button v-on:click="toggleMenu('navbar')" class="inline-flex items-center justify-center p-2 rounded-md text-green-400 hover:text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <!-- Icon when menu is closed. -->
          <!--
            Heroicon name: menu

            Menu open: "hidden", Menu closed: "block"
          -->
          <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <!-- Icon when menu is open. -->
          <!--
            Heroicon name: x

            Menu open: "block", Menu closed: "hidden"
          -->
          <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
        <div class="flex-shrink-0 flex items-center">
          <img class="block lg:hidden h-20 w-auto" src="@/assets/images/kingball.png">
          <img class="hidden lg:block h-16 w-auto" src="@/assets/images/kingball.png" alt="Workflow">
        </div>
        <div class="hidden sm:block sm:ml-6 pt-4">
          <div class="flex space-x-4">
            <!-- Current: "bg-green-900 text-white", Default: "text-green-300 hover:bg-green-700 hover:text-white" -->
            <router-link to="/" class="bg-green-900 text-white px-3 py-2 rounded-md text-sm font-medium">Papo de Boleiro</router-link>
            <button v-on:click="toggleMenu('betting')" class="inline-flex text-green-300 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Bolão Brasileiro
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>


            <!-- Betting Dropdown -->
            <div v-bind:class="{'hidden': !showBetting}"  class="absolute mt-12 w-40 left-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 z-40" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <div class="py-1">
                <router-link to="/ranking" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Ranking</router-link>
                <router-link to="/current-round" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Rodada Atual</router-link>
                <router-link v-bind:class="{'hidden': !hasPermission('UserBolao')}" :to="{ name: 'NewBetting', params: {userId: this.getUserId()}}" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Criar Palpite</router-link>
                <router-link to="/bet-rules" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Regras</router-link>
                <router-link to="/betting-saved" v-bind:class="{'hidden': !hasPermission('SuperAdmin')}" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Palpites Criados</router-link>
                <router-link to="/reports-betting" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Ranking Report</router-link>
                <a href="#" @click="updateRanking()" v-bind:class="{'hidden': !hasPermission('SuperAdmin')}" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Atualizar Ranking</a>
              </div>
              <!-- <div class="py-1">
                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Archive</a>
                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Move</a>
              </div>
              <div class="py-1">
                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Share</a>
                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Add to favorites</a>
              </div>
              <div class="py-1">
                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Delete</a>
              </div> -->
            </div>

            <a href="#" class="text-green-300 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Futebol</a>
            <a href="#" class="text-green-300 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Cartola</a>
          </div>
        </div>
      </div>


      <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        <button class="bg-green-800 p-1 rounded-full text-green-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-800 focus:ring-white">
          <span class="sr-only">View notifications</span>
          <!-- Heroicon name: bell -->
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
          </svg>
        </button>

        <!-- Profile dropdown -->
        <div class="ml-3 relative">
          <div>
            <button v-on:click="toggleMenu('profile')" class="bg-green-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-800 focus:ring-white" id="user-menu" aria-haspopup="true">
              <span class="sr-only">Open user menu</span>
              <img class="h-8 w-8 rounded-full" :src=userProfile.picture alt="">
            </button>
          </div>
          <!--
            Profile dropdown panel, show/hide based on dropdown state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          -->
          <div v-bind:class="{'hidden': !showProfile}" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-40" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <a href="#" class="block px-4 py-2 text-sm text-green-700 hover:bg-green-100" role="menuitem">Your Profile</a>
            <router-link v-bind:class="{'hidden': !hasPermission('SuperAdmin')}" to="/users" class="block px-4 py-2 text-sm text-green-700 hover:bg-green-100" role="menuitem">Users</router-link>
            <a @click=logoff class="block cursor-pointer px-4 py-2 text-sm text-green-700 hover:bg-green-100" role="menuitem">Sign out</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  -->
  <div v-bind:class="{'hidden': !showMenu}" class="sm:hidden text-left">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <!-- Current: "bg-green-900 text-white", Default: "text-green-300 hover:bg-green-700 hover:text-white" -->
      <router-link to="/" class="bg-green-900 text-white block px-3 py-2 rounded-md text-base font-medium">Papo de Boleiro</router-link>
      <button v-on:click="toggleMenu('betting-mobile')" class="inline-flex text-green-300 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-base font-medium">
        Bolão Brasileiro
        <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
        <ul v-bind:class="{'hidden': !showBettingMobile}" class="ml-6  border-double border-l-2 border-green-200 border-opacity-50">
          <li>
            <router-link to="/ranking" class="text-green-200  hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Ranking</router-link>
          </li>
          <li>
            <router-link to="/current-round" class="text-green-200  hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Rodada Atual</router-link>
          </li>
          <li>
            <router-link  :to="{ name: 'NewBetting', params: {userId: this.getUserId()}}" v-bind:class="{'hidden': !hasPermission('UserBolao')}" class="text-green-200  hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Criar Palpite</router-link>
          </li>
          <li>
            <router-link to="/bet-rules" class="text-green-200  hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Regras</router-link>
          </li>
          <li>
            <router-link to="/betting-saved" class="text-green-200  hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Palpites Cadastrados</router-link>
          </li>
          <li>
            <router-link to="/reports-betting" class="text-green-200  hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium" role="menuitem">Ranking Report</router-link>
          </li>
          <li>
            <a href="#" @click="updateRanking()" v-bind:class="{'hidden': !hasPermission('SuperAdmin')}" class="text-green-200  hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Atualizar Ranking</a>
          </li>
        </ul>
      <a href="#" class="text-green-300 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Futebol</a>
      <a href="#" class="text-green-300 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Cartola</a>
    </div>
  </div>
</nav>


    <div v-if="isGuest()" class="text-white sm:px-6 py-2 border-0 rounded relative mb-4 bg-red-500">
      <span class="inline-block align-middle mr-8">
        <b class="capitalize">Alert!</b> Aguardando aprovação dos admins
      </span>
    </div>
</div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import jwt_decode from 'jwt-decode';
import { useToast } from "vue-toastification";
import RankingService from '@/services/rankingService';

export default {
  name: 'NavigationMenu',
  setup() {
      const toast = useToast();
      return { toast }
  },
  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
    })
  },
  created() {
    let token = localStorage.getItem('token');
    if (token !== null) {
      try {
        let payload = jwt_decode(token);
        this.userData = payload;
      } catch (err) { console.log(err)}
    }
  },
  methods: {
    getUserId() {
      if (this.userData.length == 0) {
        return false;
      }
      return this.userData._id;
    },
    isGuest() {
      if (this.userData.length == 0) {
        return false;
      }
      let { rules } = this.userData;
      return rules.filter(data => data === "Guest").length > 0;
    },
    async auth() {
      await this.login();
      this.$router.push('/');
    },
    async logoff() {
      await this.logout();
      this.$router.push('/login');
    },
    toggleMenu(menu) {
        switch (menu) {
          case 'betting':
            this.showBetting = !this.showBetting
            return;
          case 'betting-mobile':
            this.showBettingMobile = !this.showBettingMobile
            return;
          case 'profile':
            this.showProfile = !this.showProfile;
            return;
          case 'navbar':
            this.showMenu = !this.showMenu;
            return;
          default:
            return;
        }
    },
    hasPermission(permission) {
      if (this.userData.length == 0) {
        return false;
      }
      let { rules } = this.userData;
      return rules.filter(data => data === permission).length > 0;
    },
    updateRanking() {
      RankingService.updateRanking().then(data => {
        console.log(data.status);
        this.toast.success("Atualizando o ranking.", {
          onClose: () => {
            location.reload()
          }
        });
      }).catch(error => {
        this.toast.error(`Erro ao atualizar: ${error.getMessage()}`);
      })
    },
    ...mapActions('user', {
      login: 'login',
      logout: 'logout'
    })
  },
  data() {
    return {
      showMenu: false,
      showProfile: false,
      showBetting: false,
      showBettingMobile: false,
      userData: '',
    }
  },
}
</script>