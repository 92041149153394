class RoundHelper {

    static getPrevRoundId(roundId) {
        let round = roundId.split("_");
        let roundNumber = round.pop();
        
        roundNumber = parseInt(roundNumber);

        let prevNumber = roundNumber - 1;
        round.push(prevNumber + '');
        return round.join("_");
    }

    static getFirstMatch(fixtures) {
        return fixtures.reduce((first, second) => {
            if (first.status == "Time to be defined" ||
                first.status == "Match Postponed") {
                return second;
            }

            return first.eventTimestamp < second.eventTimestamp ? first : second
        });
    }
}

export default RoundHelper;