
const teamName = {
    118: "BAHIA",
    119: "INTERNACIONAL",
    120: "BOTAFOGO",
    121: "PALMEIRAS",
    123: "SPORT",
    124: "FLUMINENSE",
    125: "AMÉRICA-MG",
    126: "SÃO PAULO",
    127: "FLAMENGO",
    128: "SANTOS",
    129: "CEARÁ",
    130: "GRÊMIO",
    131: "CORINTHIANS",
    132: "CHAPECOENSE",
    133: "VASCO",
    134: "ATHLETICO-PR",
    135: "CRUZEIRO",
    136: "VITÓRIA",
    140: "CRICIUMA",
    144: "ATLÉTICO-GO",
    145: "AVAÍ",
    147: "CORITIBA",
    151: "GOIAS",
    152: "JUVENTUDE",
    154: "FORTALEZA",
    794: "BRAGANTINO",
    1062:"ATLÉTICO-MG",
    1193:"CUIABÁ"
}

export default teamName;