<template>
<div>
    <NavigationMenu />

    <header class="bg-green-100 shadow-md">
      <div class="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold leading-tight text-gray-900">
          Gerenciar Usuarios
        </h1>
      </div>
    </header>

    <div class="container mx-auto lg:flex mt-4 justify-between px-2 sm:p-0 sm:mt-4">
        <div class="bg-white absolute hidden rounded-md shadow-lg ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 sm:block w-2/12">
          <div class="py-1">
              <router-link to="/users" class="block px-4 py-2 text-sm text-gray-700 hover:bg-green-700 hover:bg-opacity-20 hover:text-gray-900" role="menuitem">Usuarios</router-link>
              <router-link to="/approval" class="block px-4 py-2 text-sm text-gray-700 hover:bg-green-700 hover:bg-opacity-20 hover:text-gray-900" role="menuitem">Lista de Aprovação</router-link>
              <router-link to="/user-form" class="block px-4 py-2 text-sm text-gray-700 hover:bg-green-700 hover:bg-opacity-20 hover:text-gray-900" role="menuitem">Cadastrar usuario</router-link>
            </div>
        </div>
        
        <div class="w-full sm:w-9/12 mx-auto mr-0">
          <UsersTable />
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import NavigationMenu from '@/components/NavigationMenu.vue'
import UsersTable from '@/components/UsersTable';

export default {
  name: 'UserView',
  components: {
    NavigationMenu,
    UsersTable
  },
  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
    })
  }
}
</script>