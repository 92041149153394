<template>
  <router-view/>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";
import { mapActions } from 'vuex';

export default {
  name: 'App',
  setup() {
      const toast = useToast();
      return { toast }
  },
  components: {
  },
  methods: {
    ...mapActions('user', {
      logout: 'logout'
    })
  },
  created: function () {
    let self = this;
    axios.interceptors.response.use(response => response, function (error) {

      return new Promise(function (resolve, reject) {
        if (error.response != undefined && error.response.status === 401 && error.config) {
          self.logout();
          self.$router.push('/');
          reject(error.response)
        }

        if (error.response.status === 403 && error.config) {
          self.$router.go(-1);
          self.toast.error("Você não tem permissão para acessar esta página!");
        }

        resolve(error.response)
      });
    });
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
