import abbreviations from './teamAbbreviation';

class TeamInfoMap {
    static mapNameToAbbreviation(teamId)
    {
        return abbreviations[teamId];
    }
    
}

export default TeamInfoMap;