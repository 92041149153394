import axios from 'axios';

const url = process.env.VUE_APP_API_URL;

class BetService {

    static async getBettingSaved(roundId) {
        return new Promise((resolve, reject) => {
            axios.get(`${url}/bet/saved/${roundId}`, {
                headers: {
                    "permission": ['super-admin']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async getRanking() {
        return new Promise((resolve, reject) => {
            axios.get(`${url}/ranking`, {
                headers: {
                    "permission": ['betting']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.response);
            });
        })
    }

}

export default BetService;