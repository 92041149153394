<template>
  <div>
      <NavigationMenu />

      <header class="bg-green-100 shadow-md">
        <div class="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold leading-tight text-gray-900">
            Bolão do Brasileiro
          </h1>
        </div>
      </header>

      <div class="container mx-auto lg:flex mt-4 justify-between px-2 sm:p-0 sm:mt-4">
          <NavigationBetting />
          
          <div class="w-full sm:w-9/12 mx-auto mr-0">
            <SaveBettingForm :userData=userData :roundId=roundId />
          </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import jwt_decode from 'jwt-decode';
import NavigationMenu from '@/components/NavigationMenu';
import NavigationBetting from '@/components/NavigationBetting';
import SaveBettingForm from '@/components/SaveBettingForm';

export default {
  name: 'SaveBetting',
  components: {
    NavigationMenu,
    SaveBettingForm,
    NavigationBetting
  },
  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
    })
  },
  created() {
    let token = localStorage.getItem('token');
    if (token !== null) {
     try {
        let payload = jwt_decode(token);
        this.userData = payload;
      } catch (err) { console.log(err)}
    }
    this.roundId = this.$route.params.roundId;
  },
  data() {
    return {
      userData: null
    }
  }
}
</script>