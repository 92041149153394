<template>
    <div>
      <div class="px-2 py-2 bg-white font-bold rounded-md mb-4">
          Ranking
      </div>
      <LoadingComponent v-if="this.loading" />
      <div class="flex justify-between sm:justify-end">
        <div v-show="hasPermission('AdminBolao')"  class="w-full sm:w-4/12">
          <button @click="shareRanking" class="text-green-700 bg-white w-full hover:bg-green-800 hover:text-white font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" >
            <i class="fab fa-whatsapp"></i> Compartilhar
          </button>
        </div>
      </div>
      <div class="hidden sm:block">
        <table class="table-auto font-bold min-w-full divide-y divide-gray-200 shadow-lg rounded-lg">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                Points
              </th>
              <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                Hits
              </th>
              <th v-if="this.isRoundInitiated || hasPermission('AdminBolao')" scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(user, id) in ranking" :key="user._id" :class="getBgRows(id, ranking)">
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                    <div class="text-sm font-medium text-gray-900">
                        <span class="px-2 mr-2 text-xl inline-flex leading-5 font-semibold text-green-800">
                            {{ id + 1 }}º
                        </span>
                        <span class="font-bold">
                          {{ user.name }}
                        </span>
                    </div>
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="text-sm text-gray-900">
                  {{user.betting !== undefined ? user.betting.points : 0}}
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {{user.betting !== undefined ? user.betting.hits : 0}}
                </span>
              </td>
              <td v-if="this.isRoundInitiated || hasPermission('AdminBolao')" class="px-6 py-2 whitespace-nowrap">
                <button @click="currentRound(user._id)">
                  <i class="far fa-eye"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="block sm:hidden">
        <ol class="shadow-lg">
          <li @click="currentRound(user._id)" v-for="(user, id) in ranking" :key="user._id" :class="getBgRows(id, ranking)"  class="flex justify-between px-2 py-2 mb-1  rounded-md">
            <div class="text-3xl font-bold mr-6">
              {{ id + 1 }}º
            </div>
            <div clas="flex-col w-full align-middle">
              <div class="mt-1">
                <span class="font-bold pt-2"> {{ user.name }}</span>
              </div>
            </div>
            <div clas="flex-col w-full">
                <span class="ml-1 text-2xl font-bold">
                    {{user.betting !== undefined ? user.betting.points : 0}}
                </span>
                <span class=" ml-2 px-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-800 text-green-100">
                    + {{user.betting !== undefined ? user.betting.hits : 0}}
                </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
</template>

<script>
import RankingService from '@/services/rankingService';
import ApiService from '@/services/apiService';
import WhatsAppService from '@/services/whatsappService';
import LoadingComponent from '@/components/LoadingComponent.vue';

export default {
  name: 'RankingTable',
  components: {
    LoadingComponent,
  },
  props: [
    'userData'
  ],
  mounted() {
    RankingService.getRanking().then(data => {
      this.ranking = data;
      this.loading = false;
    });

    ApiService.getCurrentRound().then(currentRound => {
        this.isRoundInitiated = ApiService.roundInitiaded(currentRound[0].fixtures);
      })
  },
  methods: {
    getBgRows(id, ranking) {
      if (id <= 7) {
        return "bg-green-100"
      }

      let total = ranking.length;
      if (id >= (total - 1)) {
        return "bg-red-100"
      }

      return "bg-white"
    },
    currentRound(userId) {
      if (!this.isRoundInitiated) {
        return;
      }
      return this.$router.push({
          name: "Current Round",
          params: { userId: userId }
        })
    },
    hasPermission(permission) {
      if (this.userData.length == 0) {
        return false;
      }
      let { rules } = this.userData;
      return rules.filter(data => {
        return data === permission || data === "SuperAdmin"
      }).length > 0;
    },
    shareRanking() {
      WhatsAppService.shareRanking(this.ranking);
    },
  },
  data(){
    return {
      ranking: [],
      loading: true,
      isRoundInitiated: false,
    }
  },
}
</script>