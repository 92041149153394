<template>
<div>
    <NavigationMenu />

    <header class="bg-green-100 shadow-md">
      <div class="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold leading-tight text-gray-900">
          Bolão do Brasileiro
        </h1>
      </div>
    </header>

    <div class="container mx-auto lg:flex mt-4 justify-between px-2 sm:p-0 sm:mt-4">
        <NavigationBetting />
        
        <div class="w-full sm:w-9/12 mx-auto mr-0">
          <div>
            <div class="px-2 py-2 bg-white font-bold rounded-md mb-4">
                Regras
            </div>
            <div class="px-6 py-4 bg-white rounded-md mb-4 justify-start">
              <span class="font-bold ">Envio de Palpites</span>
              <ol class="list-decimal pl-2 text-left">
                <li class="mb-4">
                  Os palpites podem ser enviados no grupo do WhatsApp ou diretamente pelo App. Assim que a tabela da rodada for postada os palpites estão liberados. Não esquecer de colocar o seu nome no final do palpite.
                </li>
                <li class="mb-4">
                  Os palpites estarão liberados até o início da primeira partida da rodada. Exemplo: Primeiro jogo iniciado às 17h, palpite poderá ser postado até as 16:59h. A partir do início da rodada qualquer palpite será ignorado.
                </li>
                <li class="mb-4">
                  Em caso de partida adiada, o jogo será retirado da cartela e os palpites referente ao jogo serão anulados, e assim que a partida for remarcada será enviada uma nova tabela de palpite.
                </li>
                <li class="mb-4">
                  O Palpite do atleta após ser postado no grupo ou cadastrado no app, não poderá ser alterado, valerá portanto a primeira cartela postada por cada jogador, será permitida apenas correção com relação a erros de digitação, por exemplo, jogador com a intenção de colocar 2x0, mas acabar colocando 20x0. A administração estará avaliando cada situação.
                </li>
              </ol>
              <hr />
              <div class="my-4">
                <span class="font-bold">Critérios de desempate</span>
                <ol class="list-decimal pl-2 text-left">
                  <li class="mb-4">
                    Total de pontos
                  </li>
                  <li class="mb-4">
                    Total de placares cravados
                  </li>
                  <li class="mb-4">
                    Pagamento total do bolão realizado anteriormente ao adversário direto
                  </li>
                  <li class="mb-4">
                    Quantidades de rodadas que o jogador ficou a frente do adversário direto
                  </li>
                </ol>
              </div>
              <hr />
              <div class="my-4">
              <span class="font-bold">Premiação</span>

              <p><span class="font-semibold">1° Lugar</span> - R$ 550,00</p>
              <p><span class="font-semibold">2° Lugar</span> - R$ 375,00</p>
              <p><span class="font-semibold">3° Lugar</span> - R$ 325,00</p>
              <p><span class="font-semibold">4° Lugar</span> - R$ 250,00</p>
              <p><span class="font-semibold">5° Lugar</span> - R$ 200,00</p>
              <p><span class="font-semibold">6° Lugar</span> - R$ 150,00</p>
              <p><span class="font-semibold">7° Lugar</span> - R$ 100,00</p>
              <p><span class="font-semibold">8° Lugar</span> - R$ 50,00</p>
              </div>

              <hr />
            </div>
          </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import NavigationMenu from '@/components/NavigationMenu';
import NavigationBetting from '@/components/NavigationBetting';

export default {
  name: 'BetRules',
  components: {
    NavigationMenu,
    NavigationBetting
  },
  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
    })
  }
}
</script>