import axios from 'axios';
import ApiService from '@/services/apiService';

const url = process.env.VUE_APP_API_URL;

class RankingService {

    static async updateRanking() {
        return new Promise((resolve, reject) => {
            axios.get(`${url}/round/current`, {
                headers: {
                    "permission": ['super-admin']
                }
            })
            .then(response => {
                let currentRound = response.data[0];

                axios.put(`${url}/ranking/${currentRound._id}`, currentRound, {
                    headers: {
                        "permission": ['super-admin']
                    }
                })
                .then(response => {
                    resolve(response.data);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                });
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        })
    }

    static async getRanking() {
        return new Promise((resolve, reject) => {
            axios.get(`${url}/ranking`, {
                headers: {
                    "permission": ['betting']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.response);
            });
        })
    }

    static async getRankingReport() {
        let currentRound = await ApiService.getCurrentRound();
        let roundInitiated = ApiService.roundInitiaded(currentRound[0].fixtures);
        let path = `${url}/ranking-report`;
        if (!roundInitiated && currentRound[0]._id !== "Regular_Season_-_1") {
            path += `/${currentRound[0].prevCurrentRound}`;
        }

        return new Promise((resolve, reject) => {
            axios.get(path, {
                headers: {
                    "permission": ['betting']
                }
            })
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.response);
            });
        })
    }

}

export default RankingService;