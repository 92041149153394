<template>
    <div>
      <LoadingComponent v-if="this.loading"/>
      <div class="w-10/2">
        <div class="px-2 py-2 bg-white flex justify-between font-bold rounded-md mb-4">
          <div class="w-full">
            <select class="multiselect-wrapper bg-white" v-model="currentRound._id" @change="onChangeSelect">
              <option class="multiselect-option text-center" v-for="round in roundIds" :key="round.value" v-bind:value="round.value">{{ round.label }}</option>
            </select>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="w-full rounded-md py-2 text-white flex justify-center">
              <span class="font-bold mr-2">Total: {{getTotalPoints(currentRound)}} </span>
              <span class="px-1 h- inline-flex text-xs leading-6 h-6 font-semibold rounded-full bg-green-100 text-green-800">
                      + {{ getTotalHits(currentRound)}}
              </span>
          </div>
          <div class="w-full block sm:hidden">
            <button v-on:click="toggleBet()" class="text-green-700 bg-white w-full hover:bg-green-800 hover:text-white font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" > 
              {{ this.showBet ? 'Esconder' : 'Mostrar' }}  palpites
            </button>
           
          </div>
        </div>
        
        <ol class="shadow-lg">
          <li v-for="(fixture, id) in currentRound.fixtures" :key="id" class="bg-white px-2 py-2 mb-1 h-26 rounded-sm shadow-md flex justify-around">
            <div class="hidden sm:block w-4/12 rounded-lg border border-1 border-gray-400 shadow-md">
              Palpite
              <div class="flex justify-between mb-1">
                  <div class="w-5/12 font-bold flex justify-end mr-4">
                    <div class="pt-1">{{ this.getAbbr(fixture.homeTeam.teamId) }}</div>
                  </div>
                  <div class="w-2/12 flex justify-center">
                    <div class="mr-2 text-2xl font-bold">{{ this.getUserInfo(fixture, "goalsHomeTeam") }}</div>
                    <div class="mt-1 mr-2">x</div>
                    <div class="text-2xl font-bold">{{ this.getUserInfo(fixture, "goalsAwayTeam") }}</div>
                  </div>
                  <div class="w-5/12 font-bold flex justify-start ml-4">
                    <div class="pt-1">{{ this.getAbbr(fixture.awayTeam.teamId) }}</div>
                  </div>
              </div>

              <div class="font-thin text-sm text-gray-600">
                Points: {{ this.getUserInfo(fixture, "points")}}
              </div>
            </div>
            <div class="w-full">
              <div v-bind:class="{'line-through': fixturePostponed(fixture.status)}" class="mb-2 font-medium text-gray-600">
                {{ this.formatDate(fixture.eventDate)}} 
              </div>
              <div class="flex justify-between mb-1">
                  <div class="w-5/12 font-bold flex justify-end mr-4">
                    <div class="pt-1">{{ this.getAbbr(fixture.homeTeam.teamId) }}</div>
                    <div class="h-8 w-8 ml-2">
                      <img :src=fixture.homeTeam.teamLogo />
                    </div>
                  </div>
                  <div class="w-2/12 flex justify-center">
                    <div class="mr-2 text-2xl font-bold">{{fixture.goalsHomeTeam !== null ? fixture.goalsHomeTeam : 0}}</div>
                    <div class="mt-1 mr-2">x</div>
                    <div class="text-2xl font-bold">{{fixture.goalsAwayTeam !== null ? fixture.goalsAwayTeam : 0}}</div>
                  </div>
                  <div class="w-5/12 font-bold flex justify-start ml-4">
                    <div class="h-8 w-8 mr-2">
                      <img :src=fixture.awayTeam.teamLogo />
                    </div>
                    <div class="pt-1">{{ this.getAbbr(fixture.awayTeam.teamId) }}</div>
                  </div>
              </div>
              <div class="font-thin text-sm text-gray-600">
                {{ fixture.status }} 
              </div>
              <!-- User Bet Mobile -->
              <div v-bind:class="{'hidden': !showBet}" class="border-t-2 border-green-600 border-opacity-30 text-sm" >
                <div class="flex justify-between mb-1">
                  <div class="w-5/12 flex justify-end">
                    <div class="pt-1">{{ this.getAbbr(fixture.homeTeam.teamId) }}</div>
                  </div>
                  <div class="w-2/12 flex justify-center">
                    <div class="mr-2 text-lg">{{ this.getUserInfo(fixture, "goalsHomeTeam") }}</div>
                    <div class="mt-1 mr-2">x</div>
                    <div class="text-lg">{{ this.getUserInfo(fixture, "goalsAwayTeam") }}</div>
                  </div>
                  <div class="w-5/12 flex justify-start">
                    <div class="pt-1">{{ this.getAbbr(fixture.awayTeam.teamId) }}</div>
                  </div>
                </div>
                Points: {{ this.getUserInfo(fixture, "points")}}
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>
</template>

<script>

import LoadingComponent from '@/components/LoadingComponent';
import ApiService from '@/services/apiService';
import TeamInfoMap from '@/utils/teamInfoMap';
import { mapGetters } from 'vuex';

export default {
  name: 'CurrentRoundTable',
  components: {
    LoadingComponent,
  },
  props: [
    'userId',
    'roundId'
  ],
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        console.log(toParams, previousParams);
        this.loading = true;
        this.getData(toParams.userId, toParams.roundId);
      }
    )
  },
  mounted() {
    ApiService.getRoundIds().then(rounds => {
      let options = rounds.map(data => {
        return {
          value: data._id,
          label: this.formatTitle(data._id)
        }
      });
      this.roundIds = options;
    });

    this.getData(this.userId, this.roundId);

  },
  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
    })
  },
  methods: {

    async getData(userId, roundId) {
      if (!roundId) {
        var currentRound = await ApiService.getCurrentRound();
        roundId = currentRound[0]._id;
      }

      ApiService.getCurrentRoundWithBets(userId, roundId).then(data => {
        this.currentRound = data;
        this.loading = false;
      }).catch(err => {
        this.currentRound._id = this.roundId;
        console.log(err);
      });
    },

    getTotalHits(currentRound) {
      if (currentRound.totalHits == undefined) {
        return 0;
      }
      return currentRound.totalHits
    },
    getTotalPoints(currentRound) {
      if (currentRound.totalPoints == undefined) {
        return 0;
      }
      return currentRound.totalPoints
    },
    getAbbr(teamId) {
      return TeamInfoMap.mapNameToAbbreviation(teamId);
    },

    onChangeSelect(event) {
      let roundId = event.target.value;
      return this.$router.push({
          name: "Current Round",
          params: { userId: this.userId, roundId: roundId }
        })
    },

    formatDate(dateString)
    {
      return new Date(dateString).toLocaleString();
    },

    toggleBet() {
      this.showBet = !this.showBet;
    },

    getUserInfo(fixture, info) {

      if (fixture.userBet == undefined) {
        return "-";
      }

      switch(info) {
        case 'goalsHomeTeam':
          return fixture.userBet.goalsHomeTeam !== null ? fixture.userBet.goalsHomeTeam : 0;
        case 'goalsAwayTeam':
          return fixture.userBet.goalsAwayTeam !== null ? fixture.userBet.goalsAwayTeam : 0;
        case 'points':
          return fixture.userBet.points !== null ? fixture.userBet.points : 0;
        default:
          return 0;
      }
    },

    formatTitle(title) {
      if (title !== undefined) {
        return title.replaceAll("_", " ");
      }
      return title;
    },

    fixturePostponed(status) {
      return status == 'Time to be defined' || status == "Match Postponed";
    }
  },
  data(){
    return {
      currentRound: {},
      userBet: {},
      showBet: false,
      loading: true,
      value: null,
      roundIds : [],
    }
  },
}
</script>
<style>
@import "@vueform/multiselect/themes/default.css";
</style>