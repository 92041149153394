<template>

  <div>
    <div class="px-2 py-2 bg-white font-bold rounded-md mb-4">
        Lista de Aprovação
    </div>
    <LoadingComponent v-if="this.loading" />
    <div class="hidden sm:block">
      <table class=" table-auto min-w-full divide-y divide-gray-200 shadow-lg rounded">
        <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
              Email
            </th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
            </th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
            </th>
            <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(user, index) in users" :key="index">
            <td class="px-6 py-2 whitespace-nowrap">
              <div class="flex items-center">
                  <div class="text-sm font-medium text-gray-900">
                    {{ user.name}}
                  </div>
              </div>
            </td>
            <td class="px-6 py-2 whitespace-nowrap">
              <div class="text-sm text-gray-900">{{user.email}}</div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
              <button class="text-green-600 hover:text-green-900" @click="createNewUser(user, index)">Aceitar</button>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
              <ModalUserAssign :options=userOptions :userApproval=user @update="removeRow" :rowId=index @error="showError"/>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
              <button class="text-green-600 hover:text-green-900" @click="rejectUser(user, index)">Rejeitar</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="block sm:hidden">
      <div class="flex">
        <router-link to="/users" class="text-green-700 bg-white border-2 w-full hover:bg-green-800 hover:text-white font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" >
          Lista de Usuarios
        </router-link>
        <router-link to="/user-form" class="text-green-700 bg-white w-full hover:bg-green-800 hover:text-white font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none mb-1" type="button" style="transition: all .15s ease" > Novo usuario </router-link>
      </div>

      <ol class="list-decimal">
        <li v-for="(user, index) in users" :key="user._id" class="flex px-2 pl-0 text-left bg-white shadow-md mb-1 rounded-md border-green-800 justify-between w-full">
          <div class="w-3/12">
            <img class="h-full w-full shadow-md rounded-sm"  src="@/assets/images/player.png" alt="">
          </div>
          <div class="flex-col w-6/12 py-2 px-2">
            <p class="font-bold">{{ user.name }}</p>
            <p class="italic truncate">{{ user.email }}</p>
          </div>
          <div class="mt-2 w-3/12">
            <button class="text-white bg-green-500 w-full bg-transparent border border-solid border-green-500 hover:bg-green-800 hover:text-white font-bold uppercase text-sm px-2 py-1 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" @click="createNewUser(user, index)">
              Aceitar
            </button>
            <ModalUserAssign :options=userOptions :userApproval=user :tableStyle=false @update="removeRow" :rowId=index @error="showError"/>
            <button class="text-white bg-red-500 w-full bg-transparent border border-solid border-red-500 hover:bg-red-800 hover:text-white font-bold uppercase text-sm px-2 py-1 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" @click="rejectUser(user, index)">
              Rejeitar
            </button>
          </div>
          </li>
      </ol>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/userService';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ModalUserAssign from '@/components/ModalUserAssign'
import { useToast } from "vue-toastification";

export default {
  name: 'ApprovalTable',
  setup() {
      const toast = useToast();
      return { toast }
  },
  components: {
    ModalUserAssign,
    LoadingComponent
  },
  mounted() {
    UserService.getApprovalUsers().then(data => {
      this.users = data
      this.loading = false;
    });

    UserService.getUsers().then(users => {
      let options = users.map(data => {
        return {
          value: data._id,
          label: data.name
        }
      });
      this.userOptions = options
    })
  },
  methods: {
    removeRow(index, message) {
      if (message !== undefined) {
        this.toast.success(message);
      }
      this.users.splice(index, 1);
    },
    createNewUser(user, index) {
      user.approvalId = user._id
      UserService.assignUser(user).then(data => {
        this.toast.success(data.message);
        this.removeRow(index)
      }).catch(err => {
        this.showError(err.message)
      });
    },

    rejectUser(user, index) {
      UserService.deleteApprovalUser(user._id).then(data => {
        this.toast.success(data.message);
        this.removeRow(index)
      }).catch(err => {
        this.showError(err.message)
      });
    },
    showError(message) {
      this.toast.error(message)
    }
  },
  data(){
    return {
      users: [],
      userOptions: [],
      loading: true
    }
  },
}
</script>