<template>
    <div>
      <div class="px-2 py-2 bg-white font-bold rounded-md mb-0">
          Ranking Report <br>
      </div>
      <LoadingComponent v-if="this.loading" />
      <span class="font-thin text-xs text-white" v-if="!this.loading">Atualizado em: {{ rankingReport.date }}</span>
      <div class="flex justify-between sm:justify-end">
        <div v-show="hasPermission('AdminBolao')"  class="w-full sm:w-4/12">
          <button @click="shareRanking" class="text-green-700 bg-white w-full hover:bg-green-800 hover:text-white font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" >
            <i class="fab fa-whatsapp"></i> Compartilhar
          </button>
        </div>
      </div>
      <div class="hidden sm:block">
        <table class="table-auto font-bold min-w-full divide-y divide-gray-200 shadow-lg rounded-lg">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                Points
              </th>
              <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                Hits
              </th>
              <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(user, id) in rankingReport.ranking" :key="user.userId" :class="getBgRows(id, rankingReport.ranking)">
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                    <div class="text-sm font-medium text-gray-900">
                        <span class="px-2 mr-2 text-xl inline-flex leading-5 font-semibold text-green-800">
                            {{ id + 1 }}º
                        </span>
                        <span class="font-bold">
                          {{ user.userName }}
                        </span>
                    </div>
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="text-sm text-gray-900">
                  {{user.totalPoints !== undefined ? user.totalPoints : 0}}
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {{user.totalHits !== undefined ? user.totalHits : 0}}
                </span>
              </td>
              <td class="px-6 py-2 whitespace-nowrap text-xs">
                <div v-html="getPosition(user)"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="block sm:hidden">
        <ol class="shadow-lg">
          <li v-for="(user, id) in rankingReport.ranking" :key="user.userId" :class="getBgRows(id, rankingReport.ranking)"  class="flex justify-between px-2 py-2 mb-1  rounded-md">
            <div class="text-xl font-bold mr-6">
              {{ id + 1 }}º
            </div>
            <div clas="flex-col w-full align-middle">
              <div class="mt-2 text-xs">
                <span class="font-bold pt-2"> {{ user.userName }}</span>
              </div>
            </div>
            <div clas="flex-col w-full">
                <span class="ml-3 text-base font-bold">
                    {{user.totalPoints !== undefined ? user.totalPoints : 0}}
                </span>
                <span class=" ml-1 px-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-800 text-green-100">
                    + {{user.totalHits !== undefined ? user.totalHits : 0}}
                </span>
                <span style="font-size: .50rem" class="ml-2 leading-5 align-middle" v-html="getPosition(user)"></span>
            </div>
          </li>
        </ol>
      </div>
    </div>
</template>

<script>
import RankingService from '@/services/rankingService';
import WhatsAppService from '@/services/whatsappService';
import LoadingComponent from '@/components/LoadingComponent.vue';

export default {
  name: 'RankingReportTable',
  components: {
    LoadingComponent,
  },
  props: [
    'userData'
  ],
  mounted() {
    RankingService.getRankingReport().then(data => {
      this.rankingReport = data;
      this.loading = false;
    });
  },
  methods: {
    getBgRows(id, ranking) {
      if (id <= 7) {
        return "bg-green-100"
      }

      let total = ranking.length;
      if (id >= (total - 1)) {
        return "bg-red-100"
      }
      return "bg-white"
    },
    getPosition(user) {
      let position = user.lastPosition - user.position;

      if (user.lastPosition == 0) {
        position = 0
      }

      if (position > 0) {
        return `<span class="text-green-700"><i class="fas fa-sort-up"></i>${position}</span>`;
      }

      if (position < 0) {
        return `<span class="text-red-700"><i class="fas fa-sort-down"></i>${position * -1}</span>`;
      }

      return `<span class="text-grey-700"><i class="fas fa-minus-circle"></i> </span>`;
    },
    hasPermission(permission) {
      if (this.userData.length == 0) {
        return false;
      }
      let { rules } = this.userData;
      return rules.filter(data => {
        return data === permission || data === "SuperAdmin"
      }).length > 0;
    },
    shareRanking() {
      WhatsAppService.shareRanking(this.rankingReport.ranking, true);
    },
  },
  data(){
    return {
      rankingReport: [],
      loading: true,
    }
  },
}
</script>