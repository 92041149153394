import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from '@/store';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_API_ID,
}

initializeApp(firebaseConfig);
const auth = getAuth();

// https://firebase.google.com/docs/auth/web/manage-users
onAuthStateChanged(auth, function(user) {
  if (user) {
    store.commit(`user/USER_DETAILS`, user);
  }
});