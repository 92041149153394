import teamName from '@/utils/teamName';
import ApiService from '@/services/apiService';

class WhatsAppService {

    static async shareRanking (ranking, report = false) {

        let title = await this.getRankingTitle();

        let body = await this.getRankingBody(ranking, report);

        let bottom = `\n\r *ATUALIZADA!!!*`;

        let fullRanking = title + body + bottom;

        fullRanking = window.encodeURIComponent(fullRanking);

        this.sendMessage(fullRanking);

    }

    static async getRankingBody(ranking,report = false) {

        let body = ``;
        if (report) {
            ranking.forEach((user, index) => {

                let bodyMessage = `${index + 1}º - ${user.userName} - ${user.totalPoints} + ${user.totalHits}`;
                if (index < 10) {
                    bodyMessage = `*${bodyMessage}*`;
                }

                body += `${bodyMessage} \n\r `;
            });
            return body;
        }

        ranking.forEach((user, index) => {

            let bodyMessage = `${index + 1}º - ${user.name} - ${user.betting.points} + ${user.betting.hits}`;
            if (index < 11) {
                bodyMessage = `*${bodyMessage}*`;
            }

            body += `${bodyMessage} \n\r `;
        });

        return body;
    }

    static async getRankingTitle() {

        let currentRound = await ApiService.getCurrentRound();

        let isRoundAlreadyStarted = ApiService.roundInitiaded(currentRound[0].fixtures);

        let header = `*BOLÃO BRASILEIRÃO ${(new Date).getFullYear()}!!!* \n\r \n\r`;

        let roundId = currentRound[0]._id.split("_");

        roundId = parseInt(roundId[roundId.length -1]);

        if (!isRoundAlreadyStarted) {
            --roundId;
        }

        header += `*${roundId}º - RODADA* \n\r \n\r`;

        return header;
    }

    static shareBet (bet, user, userName = null) {

        let title = this.getBetRoundTitle(bet._id);
        console.log(bet, user, userName);

        let body = this.getBetBody(bet.fixtures, user.rules);

        let player = this.getPlayer(user, userName);

        let bottom = this.getBetBottom(bet.fixtures);

        let fullBet = title + body + player + bottom;

        fullBet = window.encodeURIComponent(fullBet);

        this.sendMessage(fullBet);
    }

    static sendMessage (message) {
        window.location.href = `https://api.whatsapp.com/send?text=${message}`;
    }

    static getBetRoundTitle (rountId) {
        let name = rountId.split("_");

        return `*BOLÃO BRASILEIRÃO ${(new Date).getFullYear()}!!!* \n\r \n\r*${name[name.length -1]}º RODADA!!!* \n\r \n\r \n\r`;
    }

    static getBetBody (fixtures, rules) {
        let body = "";

        let isSuperAdmin = rules.filter(data => data === "SuperAdmin").length > 0;

        fixtures.forEach(element => {
            if (element.status != "Not Started" && !isSuperAdmin) {
                return;
            }
            body += `${teamName[element.homeTeam.teamId]} ${element.goalsHomeTeam ?? ""} X ${element.goalsAwayTeam ?? ""} ${teamName[element.awayTeam.teamId]} \n\r \n\r`;
        });

        return body;
    }

    static getPlayer (user, userName = null) {
        var name = userName ?? user.name;
        return `*JOGADOR* *${name}* \n\r \n\r`;
    }

    static getBetBottom (fixtures) {
        let firstMatch = this.getFirstMatch(fixtures);

        let newDate = new Date((firstMatch.eventTimestamp * 1000));
        let dateString = newDate.toLocaleString("en-US", {timeZone: "America/Sao_Paulo"});
        let eventTimeStamp = new Date(dateString);


        let weekDays = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sabado"];

        let date = eventTimeStamp.toLocaleDateString('pt-br');
        let time = eventTimeStamp.toLocaleTimeString('pt-br');
        time = time.substr(0, (time.length -3))

        return `*PALPITES FECHA ÀS ${time}HS DIA  - ${date} - ${weekDays[eventTimeStamp.getDay()]}* \n\r \n\r _by app_`;
    }

    static getFirstMatch(fixtures) {
        return fixtures.reduce((first, second) => {
            if (first.status == "Time to be defined" ||
                first.status == "Match Postponed" ||
                first.status != "Not Started") {
                return second;
            }

            return first.eventTimestamp < second.eventTimestamp ? first : second
        });
    }
}

export default WhatsAppService;