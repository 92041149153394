import { createApp } from 'vue'
import Toast from "vue-toastification";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/styles/tailwind.css';
import './utils/auth';
import "vue-toastification/dist/index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const options = {
    position: "top-center",
    timeout: 5000,
};

createApp(App)
    .use(store)
    .use(router)
    .use(Toast, options)
    .mount('#app')
