<template>
    <div>
      <div class="px-2 py-2 bg-white font-bold rounded-md mb-4">
          Usuários
      </div>
      <LoadingComponent v-if="this.loading" />
      <div class="flex justify-between">
        <div class="w-full rounded-md py-2 text-white flex justify-center">
            <input v-model="searchUser" type="text" class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Nome do usuario">
        </div>
      </div>
      <div class="hidden sm:block">
        <table class=" table-auto min-w-full divide-y divide-gray-200 shadow-lg rounded">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                Rules
              </th>
              <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
              </th>
              <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
              </th>
              <th v-bind:class="{'hidden': !hasPermission('SuperAdmin')}" scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(user) in filteredUsers" :key="user._id">
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                    <div class="text-sm font-medium text-gray-900">
                      {{ user.name}}
                    </div>
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{user.email}}</div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="text-xs text-gray-900">
                  <ul>
                    <li v-for="(rule, id) in user.rules" :key="id" >
                        {{ rule }}
                    </li>
                  </ul>
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <router-link :to="{ name: 'UserForm', params: {userId: user._id}}" class="text-green-600 hover:text-green-900">Edit</router-link>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button :disabled="deleteDisable" @click="deleteUser(user._id)" class="text-green-600 hover:text-green-900">Delete</button>
              </td>
              <td v-bind:class="{'hidden': !hasPermission('SuperAdmin')}" class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <router-link :to="{ name: 'NewBetting', params: {userId: user._id}}" class="text-green-600 hover:text-green-900">Criar Palpite</router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="block sm:hidden">
        <div class="flex">
          <router-link to="/approval" class="text-green-700 bg-white w-full border-2 hover:bg-green-800 hover:text-white font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" >
            Lista de aprovação
          </router-link>
          <router-link to="/user-form" class="text-green-700 bg-white w-full hover:bg-green-800 hover:text-white font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none mb-1" type="button" style="transition: all .15s ease" > Novo usuario </router-link>
        </div>

        <ol class="list-decimal">
          <li v-for="(user, index) in filteredUsers" :key="index"  class="flex px-2 pl-0 text-left bg-white shadow-md mb-1 rounded-md border-green-800 justify-between w-full">
            <div class="w-3/12">
              <img class="h-full w-full shadow-md rounded-sm"  src="@/assets/images/player.png" alt="">
            </div>
            <div class="flex-col w-6/12 py-2 px-2">
              <p class="font-bold">{{ user.name }}</p>
              <p class="italic truncate">{{ user.email }}</p>
              <p class="italic text-xs text-blue-800">{{ user.rules }}</p>
            </div>
            <div class="mt-2 w-3/12">
              <router-link :to="{ name: 'Users', params: {userId: user._id}}" class="text-white  text-center bg-green-500 w-full sm:bg-transparent border border-solid border-green-500 hover:bg-green-800 hover:text-white font-bold uppercase text-sm px-2 py-1 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease">
                Update
              </router-link>
              <button :disabled="deleteDisable" @click="deleteUser(user._id, index)" class="text-white bg-red-500 w-full sm:bg-transparent border border-solid border-red-500 hover:bg-red-900 hover:text-white font-bold uppercase text-sm px-2 py-1 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease">
                Delete
              </button>
              <router-link :to="{ name: 'NewBetting', params: {userId: user._id}}" class="text-white text-center bg-indigo-900 w-full sm:bg-transparent border border-solid border-indigo-500 hover:bg-indigo-900 hover:text-white font-bold uppercase text-sm px-2 py-1 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease">
                Criar Palpite
              </router-link>
            </div>
          </li>
        </ol>
      </div>
    </div>
</template>

<script>
import UserService from '@/services/userService';
import LoadingComponent from './LoadingComponent.vue';
import jwt_decode from 'jwt-decode';
import { mapGetters } from 'vuex';
import { useToast } from "vue-toastification";

export default {
  name: 'UserTable',
  components: {
    LoadingComponent,
  },
  setup() {
      const toast = useToast();
      return { toast }
  },
  created() {
    let token = localStorage.getItem('token');
    if (token !== null) {
     try {
        let payload = jwt_decode(token);
        this.userData = payload;
      } catch (err) { console.log(err)}
    }
  },
  computed: {
    filteredUsers() {
      let users = [];
      users = this.users.filter((user) => {
        return (
          user.name.toLowerCase().indexOf(this.searchUser.toLowerCase()) > -1
        );
      });

      return users;
    },
    ...mapGetters('user', {
      userProfile: 'userProfile',
    })
  },
  mounted() {
    UserService.getUsers().then(data => {
      this.users = data;
      this.loading = false;
    })
  },
  methods: {
    hasPermission(permission) {
      if (this.userData.length == 0) {
        return false;
      }
      let { rules } = this.userData;
      return rules.filter(data => data === permission).length > 0;
    },
    deleteUser(userId) {
      this.deleteDisable = true;
      if (confirm("Deseja deletar o usuario?")) {
        UserService.deleteUser(userId).then(() => {
          this.toast.success("Usuario deletado com sucesso", {
            onClose: () => {
              location.reload();
            }
          });
        })
        return;
      }
      this.deleteDisable = false;
    },
     removeRow(index, message) {
      if (message !== undefined) {
        this.toast.success(message);
      }
      this.users.splice(index, 1);
    },
  },
  data(){
    return {
      searchUser: "",
      users: [],
      deleteDisable: false,
      loading: true,
    }
  },
}
</script>