<template>
<div>
    <NavigationMenu />

    <header class="bg-green-100 shadow-md">
      <div class="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold leading-tight text-gray-900">
          Gerenciar Usuarios
        </h1>
      </div>
    </header>

    <div class="container mx-auto lg:flex mt-4 justify-between px-2 sm:p-0 sm:mt-4">
        <div class="bg-white hidden rounded-md shadow-lg ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 sm:block w-2/12">
          <div class="py-1">
              <router-link to="/users" class="block px-4 py-2 text-sm text-gray-700 hover:bg-green-700 hover:bg-opacity-20 hover:text-gray-900" role="menuitem">Usuarios</router-link>
              <router-link to="/approval" class="block px-4 py-2 text-sm text-gray-700 hover:bg-green-700 hover:bg-opacity-20 hover:text-gray-900" role="menuitem">Lista de Aprovação</router-link>
              <router-link to="/user-form" class="block px-4 py-2 text-sm text-gray-700 hover:bg-green-700 hover:bg-opacity-20 hover:text-gray-900" role="menuitem">Cadastrar usuario</router-link>
            </div>
        </div>
        
        <div class="w-full sm:w-9/12 mx-auto">
          <div class="block sm:hidden">

            <div class="px-2 py-2 bg-white font-bold rounded-md mb-4">
                {{ !this.userId ? 'Cadastrar' : 'Atualizar' }} Usuario
            </div>

            <div class="flex">
              <router-link to="/approval" class="text-green-700 bg-white w-full border-2 hover:bg-green-800 hover:text-white font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" >
                Lista de aprovação
              </router-link>
              <router-link to="/users" class="text-green-700 bg-white border-2 w-full hover:bg-green-800 hover:text-white font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" >
                Lista de Usuarios
              </router-link>
            </div>
          </div>

          <form id="user-form" @submit="submitForm">
            <div class="flex flex-col justify-center">
                <div class="relative px-4 py-4 sm:py-10 bg-white sm:mx-8 md:mx-0 shadow rounded-md sm:p-10 w-full mx-auto">
                  <div class="max-w-md mx-auto">
                    <div class="justify-center hidden sm:block">
                      <div class="block pl-2 font-semibold text-xl text-gray-700">
                        <h2 class="">{{ !this.userId ? 'Cadastrar' : 'Atualizar' }} Usuario</h2>
                      </div>
                    </div>
                    <div class="divide-y divide-gray-200">
                      <div class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                        <div class="flex flex-col items-start">
                          <label class="leading-loose">Nome *</label>
                          <input v-model="user.name" type="text" class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Nome do usuario">
                        </div>
                        <div class="flex flex-col items-start">
                          <label class="leading-loose">Email</label>
                          <input v-model="user.email" type="text" class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Email do usuario">
                        </div>
                        <div class="flex flex-col items-start">
                          <label class="leading-loose">Rules</label>
                            <fieldset>
                              <div class="mt-4 space-y-4">
                                <div class="flex items-start">
                                  <div class="flex items-center h-5">
                                    <input id="superadmin" v-model="this.user.rules" type="checkbox" value="SuperAdmin"  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                                  </div>
                                  <div class="ml-3 text-sm">
                                    <label for="superadmin"  class="font-medium text-gray-700">Super Admin</label>
                                  </div>
                                </div>
                                <div class="flex items-start">
                                  <div class="flex items-center h-5">
                                    <input id="adminfutebol" v-model="this.user.rules" type="checkbox" value="AdminFutebol" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                                  </div>
                                  <div class="ml-3 text-sm">
                                    <label for="adminfutebol" class="font-medium text-gray-700">Admin Futebol</label>
                                  </div>
                                </div>
                                <div class="flex items-start">
                                  <div class="flex items-center h-5">
                                    <input id="adminbolao" v-model="this.user.rules" type="checkbox" value="AdminBolao"  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                                  </div>
                                  <div class="ml-3 text-sm">
                                    <label for="adminbolao" class="font-medium text-gray-700">Admin Bolão</label>
                                  </div>
                                </div>
                                <div class="flex items-start">
                                  <div class="flex items-center h-5">
                                    <input id="userbolao" v-model="this.user.rules" type="checkbox" value="UserBolao"  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                                  </div>
                                  <div class="ml-3 text-sm">
                                    <label for="userbolao" class="font-medium text-gray-700">User Bolão</label>
                                  </div>
                                </div>
                                <div class="flex items-start">
                                  <div class="flex items-center h-5">
                                    <input id="userfutebol" v-model="this.user.rules" type="checkbox" value="UserFutebol"  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                                  </div>
                                  <div class="ml-3 text-sm">
                                    <label for="userfutebol" class="font-medium text-gray-700">User Futebol</label>
                                  </div>
                                </div>
                                <div class="flex items-start">
                                  <div class="flex items-center h-5">
                                    <input id="user" v-model="this.user.rules" type="checkbox" value="User"  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                                  </div>
                                  <div class="ml-3 text-sm">
                                    <label for="user" class="font-medium text-gray-700">User</label>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                        </div>
                      </div>
                      <div class="pt-4 flex items-center space-x-4">
                          <button :disabled="disableSubmit" class="bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">{{ !this.userId ? 'Cadastrar' : 'Atualizar' }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </form>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import NavigationMenu from '@/components/NavigationMenu';
import UserService from '@/services/userService';
import { useToast } from "vue-toastification";

export default {
  name: 'UserForm',
  components: {
    NavigationMenu
  },
  setup() {
      const toast = useToast();
      return { toast }
  },
  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
    })
  },
  mounted() {
    this.userId = this.$route.params.userId;
    if (this.userId || this.userId.length > 0) {
      UserService.getUser(this.userId).then(data => {
        this.user = data
      })
    }
    
  },
  methods: {
    async submitForm(e) {
      this.disableSubmit = true;
      if (!this.isValidForm()) {
        this.toast.error("Dados do usuario inválidos");
        return true;
      }
      e.preventDefault();

      UserService.saveUser(this.user).then((data) => {
        console.log(data.status);
        this.toast.success("Usuario salvo com sucesso!", {
          onClose: () => {
            this.$router.push('/users');
          }
        });
      });
    },
    isValidForm() {
      console.log(this.user.name);
      if (this.user.name == undefined || this.user.name.length <= 0) {
        return false;
      }
      return true;
    }

  },
  data(){
    return {
      user: {
        rules: []
      },
      userId: null,
      disableSubmit: false,
    }
  },
}
</script>